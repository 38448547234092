/**
 * https://developers.google.com/tag-platform/devguides/consent#gtag.js_1
 */

type GoogleConsent = {
    ad_storage: 'denied' | 'granted';
    analytics_storage: 'denied' | 'granted';
};

export default class CookieConsent {
    $el: HTMLElement;
    $btnAcceptAll: HTMLElement;
    $btnSavePreferences: HTMLElement;
    $inputAnalytics: HTMLInputElement;
    $inputMarketing: HTMLInputElement;

    constructor($el: HTMLElement) {
        this.$el = $el;
        this.$btnAcceptAll = this.$el.querySelector('.js-cookieConsentBtnAcceptAll')!;
        this.$btnSavePreferences = this.$el.querySelector('.js-cookieConsentBtnSavePreferences')!;
        this.$inputAnalytics = document.getElementById('cookieConsentAnalytics')! as HTMLInputElement;
        this.$inputMarketing = document.getElementById('cookieConsentMarketing')! as HTMLInputElement;

        this.initListeners();
    }

    initListeners(): void {
        this.$btnAcceptAll.addEventListener('click', this.onClickAcceptAll.bind(this));
        this.$btnSavePreferences.addEventListener('click', this.onClickSavePreferences.bind(this));

        this.$el.addEventListener(
            'animationend',
            (e) => {
                if (e.target === this.$el) {
                    this.$el.classList.add('is-hidden');
                }
            },
            false
        );
    }

    onClickAcceptAll(): void {
        const consent: GoogleConsent = {
            ad_storage: 'granted',
            analytics_storage: 'granted',
        };

        this.update(consent);
    }

    onClickSavePreferences(): void {
        const consent: GoogleConsent = {
            ad_storage: this.$inputMarketing.checked ? 'granted' : 'denied',
            analytics_storage: this.$inputAnalytics.checked ? 'granted' : 'denied',
        };

        this.update(consent);
    }

    update(consent: GoogleConsent): void {
        if (window.gtag) {
            gtag('consent', 'update', consent);
        }

        localStorage.setItem('cookie-consent', JSON.stringify(consent));
        this.$el.classList.add('is-hide');
    }
}
