export default class Newsletter {
    $el: HTMLElement;
    $form: HTMLFormElement;
    $email: HTMLInputElement;
    $mergeFields: HTMLElement;

    constructor($el: HTMLElement) {
        this.$el = $el;
        this.$form = this.$el.querySelector('.js-newsletterForm')!;
        this.$mergeFields = this.$el.querySelector('.js-newsletterMergeFields')!;
        this.$email = this.$el.querySelector('input[name="email"]')!;

        this.initListeners();
    }

    initListeners(): void {
        this.$email.addEventListener('focus', (e) => {
            this.$mergeFields.style.maxHeight = this.$mergeFields.scrollHeight + 'px';
            this.isShowMergeFields = true;
        });

        this.$form.addEventListener('xhrform:success', (e) => {
            this.$mergeFields.style.maxHeight = '0px';
            this.isShowMergeFields = false;
        });
    }

    /**
     * Getters & setters
     */

    get isShowMergeFields(): boolean {
        return this.$el.classList.contains('is-showMergeFields');
    }

    set isShowMergeFields(isShowMergeFields: boolean) {
        this.$el.classList.toggle('is-showMergeFields', isShowMergeFields);
    }
}
