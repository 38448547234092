import gsap from 'gsap';

export default class Header {
    $$backdrops: NodeListOf<HTMLElement>;
    $el: HTMLElement;
    $backdrops: HTMLElement | null;
    interval: number | null;
    numBackdrops: number;
    timeout: number | null;

    constructor($el: HTMLElement) {
        this.$el = $el;
        this.$$backdrops = this.$el.querySelectorAll('.js-headerBackdrop');
        this.$backdrops = this.$el.querySelector('.js-headerBackdrops');
        this.interval = null;
        this.numBackdrops = this.$$backdrops.length;
        this.timeout = +(this.$el.dataset.headerTimeout ?? 5000);

        if (this.$backdrops) {
            this.initListeners();
            this.start();
        }
    }

    initListeners(): void {
        this.$el.addEventListener(
            'equinox:toggle',
            ((e: CustomEvent) => {
                if (e.detail.isVisible) {
                    this.start();
                } else {
                    this.stop();
                }
            }) as EventListener,
            false
        );

        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible') {
                this.start();
            } else {
                this.stop();
            }
        });
    }

    showNext(): void {
        if (this.$backdrops) {
            const $first = this.$backdrops.firstChild;

            if ($first instanceof HTMLElement) {
                if (!this.$el.classList.contains('is-animating')) {
                    this.$el.classList.add('is-animating');
                }

                this.$backdrops.appendChild($first);
            }
        }
    }

    start(): void {
        if (this.interval === null && this.timeout && this.numBackdrops > 1) {
            this.interval = setInterval(this.showNext.bind(this), this.timeout);
        }
    }

    stop(): void {
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;
        }
    }
}
