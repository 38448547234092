type XhrFormResponse = {
    data: { [key: string]: any };
};

type XhrFormError = {
    response: {
        data: { [key: string]: any };
        status: number;
    };
};

export default class XhrForm {
    $el: HTMLFormElement;
    $errors: HTMLUListElement | null;
    headers: { [key: string]: string };
    method: string | null;
    url: string | null;

    constructor($el: HTMLFormElement) {
        this.$el = $el;
        this.$errors = this.$el.querySelector('.js-xhrFormErrors');
        this.headers = {
            Accept: 'application/json',
            'Cache-Control': 'no-cache',
        };
        this.method = $el.getAttribute('method');
        this.url = $el.getAttribute('action');

        if (this.method === null) {
            console.error("Property 'method' not defined.");
            return;
        }

        if (this.url === null) {
            console.error("Property 'action' not defined.");
            return;
        }

        this.initListeners();
    }

    initListeners() {
        this.$el.addEventListener('submit', this.onSubmit.bind(this));
    }

    displayError(error: string): void {
        if (this.$errors) {
            this.$errors.appendChild(
                Object.assign(document.createElement('li'), {
                    textContent: error,
                })
            );
        }
    }

    displayErrors(errors: { [key: string]: string[] }): void {
        let $field: HTMLElement | null;
        let $fieldError: HTMLElement | null;
        let $input: HTMLInputElement | null;
        let name: string;

        for (const key in errors) {
            errors[key].forEach((error) => {
                this.displayError(error);
            });

            name = key.replace(/\./g, '[') + Array(key.split('.').length).join(']');
            $input = this.$el.querySelector(`[name="${name}"]`);

            if (!$input) {
                $input = this.$el.querySelector(`[name="${name}[]"]`);
            }

            if ($input) {
                $field = $input.closest('.js-xhrFormField');

                if ($field) {
                    $field.classList.add('has-error');
                    $fieldError = $field.querySelector('.js-xhrFormFieldError');

                    if ($fieldError) {
                        $fieldError.innerText = errors[key].pop() ?? '';
                    }
                }
            }
        }
    }

    handleError(error: XhrFormError) {
        this.reset().then(() => {
            this.$el.dispatchEvent(new CustomEvent('xhrform:complete', { detail: error.response.data }));

            switch (error.response.status) {
                case 403:
                    console.error('CSRF Token mismatch.');
                    break;

                case 422:
                    this.$el.dispatchEvent(new CustomEvent('xhrform:validationerror'));
                    this.hasErrors = true;

                    window.requestAnimationFrame(() => {
                        if (error.response.data.result === 'error') {
                            this.displayError(error.response.data?.message);
                        } else {
                            this.displayErrors(error.response.data?.errors);
                        }
                    });

                    break;

                default:
                    this.$el.dispatchEvent(new CustomEvent('xhrform:servererror'));
                    this.hasServerError = true;
                    break;
            }
        });
    }

    handleSuccess(response: XhrFormResponse) {
        if (response.data?.location) {
            window.location = response.data.location;
        }

        this.reset().then(() => {
            this.$el.dispatchEvent(new CustomEvent('xhrform:complete', { detail: response.data }));
            this.$el.dispatchEvent(new CustomEvent('xhrform:success', { detail: response.data }));
            this.isSuccess = response.data?.success !== undefined;
        });
    }

    async onSubmit(e: Event) {
        e.preventDefault();

        if (this.method && this.url) {
            if (this.$el.dataset.xhrFormConfirm) {
                if (!confirm(this.$el.dataset.xhrFormConfirm)) {
                    return false;
                }
            }

            this.$el.dispatchEvent(new CustomEvent('xhrform:beforesend'));
            this.isWaiting = true;

            try {
                const formData = new FormData(this.$el);
                const response = await fetch(this.url, {
                    cache: 'no-cache',
                    method: this.method,
                    body: new FormData(this.$el),
                });

                this.$el.dispatchEvent(new CustomEvent('xhrform:send', { detail: formData }));

                const json = await response.json();
                const { status } = response;

                if (status !== 200) {
                    throw new Error(
                        JSON.stringify({
                            response: {
                                data: json,
                                status: status,
                            },
                        })
                    );
                }

                this.handleSuccess({ data: json });
            } catch (error) {
                error = JSON.parse(error.message);
                this.handleError(error);
            }
        }
    }

    reset(): Promise<boolean> {
        return new Promise((resolve) => {
            this.hasErrors = false;
            this.hasServerError = false;
            this.isSuccess = false;
            this.isWaiting = false;

            if (this.$errors) {
                this.$errors.innerHTML = '';
            }

            this.$el.querySelectorAll('.js-xhrFormField').forEach(($field) => {
                $field.classList.remove('has-error');
            });

            this.$el.querySelectorAll('.js-xhrFormFieldError').forEach(($fieldError) => {
                $fieldError.innerHTML = '';
            });

            requestAnimationFrame(() => {
                resolve(true);
            });
        });
    }

    /**
     * Getters & setters
     */

    get hasErrors(): boolean {
        return this.$el.classList.contains('has-errors');
    }

    set hasErrors(hasErrors: boolean) {
        this.$el.classList.toggle('has-errors', hasErrors);
    }

    get hasServerError(): boolean {
        return this.$el.classList.contains('has-serverError');
    }

    set hasServerError(hasServerError: boolean) {
        this.$el.classList.toggle('has-serverError', hasServerError);
    }

    get isSuccess(): boolean {
        return this.$el.classList.contains('is-success');
    }

    set isSuccess(isSuccess: boolean) {
        this.$el.classList.toggle('is-success', isSuccess);
    }

    get isWaiting(): boolean {
        return this.$el.classList.contains('is-waiting');
    }

    set isWaiting(isWaiting: boolean) {
        this.$el.classList.toggle('is-waiting', isWaiting);
    }
}
